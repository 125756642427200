<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
         style="width: 150px"
          :src="appDarkLogo"
          alt="logo"
        />

      
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Reset Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Your new password must be different from previously used passwords
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required"
                >
                 <label class="required-l" for="reset-password-new">New Password</label>
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                 <label class="required-l" for="reset-password-confirm">Confirm Password</label>
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' :'EyeIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeOffIcon':'EyeIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeOffIcon':'EyeIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      const self = this
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post('/reset-password', {
            token: self.$route.query.token,
            email: self.$route.query.email,
            password: self.password,
            password_confirmation: self.cPassword,
          })
            .then(res => {
              if (res.data.status) {
                this.password = ''
                this.cPassword = ''
                this.$refs.simpleRules.reset()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password Updated',
                    icon: 'BellIcon',
                    text: res.data.message,
                    variant: 'success',
                  },
                })
                 this.$router.push({ name: 'auth-login' })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password Reset Failed',
                    icon: 'BellIcon',
                    text: res.data.message,
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              // Do something on error
              if (error.response.status === 422) {
                if (typeof error.response.data.errors !== 'undefined') {
                  const keys = Object.keys(error.response.data.errors)
                  const values = Object.values(error.response.data.errors)
                  for (let i = 0; i <= keys.length; i += 1) {
                    if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Password Reset Failed',
                          icon: 'BellIcon',
                          text: values[0][0],
                          variant: 'danger',
                        },
                      })
                    }
                  }
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password Reset Failed',
                    icon: 'BellIcon',
                    text: 'Oops! Something Went Wrong',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app
    return {
      appName,
      appDarkLogo,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    .brand-text{
        line-height: 42px;
        margin-bottom: 0;
    }
</style>
